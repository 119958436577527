import { createRoot } from "react-dom/client";
import App from "./App";
import "./assets/common/common.css";

import { Provider } from "react-redux";
import { store } from "./store/store";

// Selecciona el elemento raíz en tu HTML
const container = document.getElementById("root");

// Crea una raíz y renderiza tu aplicación
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
